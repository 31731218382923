import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Education`}</p>
    <p>{`ERPNext for Education Institutes
ERPNext Education module helps you manage your entire educational institution from admissions to assessments.`}</p>
    <p>{`Try ERPNext free for 14 days`}</p>
    <p>{`Student Data
A single source of all information regarding students from guardians to attendance.
Admissions
Create admission criteria and conduct online admissions with payments via the portal.
Attendance
Manage the attendance of your students groups and batches with a simple click.
Course Scheduling
Create a schedule of courses for each team, enroll students in programs and groups.
Assessment
The assessment module lets you create complex criteria for each course and student and manage grades.
Fee Collection
Track collection of fees by creating fee schedules and sending fee requests that can be paid on the portal.
Integrate with your favourite Apps
ERPNext has a host of built-in integrations that help your business take-off faster`}</p>
    <p>{`ERPNext Integrations`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      